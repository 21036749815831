<template>
	<div>
		<v-row v-if="invoice" style="margin: 10px">
			<v-col style="max-width: 900px" class="mx-auto">
				<InvoiceForm :invoice="invoice" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Vue from "vue";

import InvoiceForm from "../components/InvoiceForm";

import { getInvoices } from "../api/invoices";

export default Vue.extend({
	name: "InvoiceDetail",
	components: {
		InvoiceForm,
	},
	created() {
		this.handleGetInvoice();
	},
	watch: {
		"$route.params.id": function () {
			this.handleGetInvoice();
		},
	},
	data() {
		return {
			invoice: null,
		};
	},
	methods: {
		async handleGetInvoice() {
			const response = await getInvoices(0, 1, null, null, { search: this.$route.params.id });

			if (response.status === 200) {
				this.invoice = response.data.invoices[0];
			} else {
				this.$router.replace("/invoices");
			}
		},
	},
});
</script>
